import React from "react";
import PropTypes from "prop-types";
import { InputText, Label, Dropdown } from "../../../../components/Inputs";
import { STATUS_LABELS, STATUS_IDS } from "../../../../components/Utils";

export default class ADConnectionForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.shape({
      adConnection: PropTypes.string,
      adUsername: PropTypes.string,
      adPassword: PropTypes.string,
      adCellNumber: PropTypes.string,
      vf_email: PropTypes.string,
    }),
    readOnly: PropTypes.bool,
    vfEmailReadable: PropTypes.bool,
    vfEmailEditable: PropTypes.bool,
  };
  static defaultProps = {
    className: "",
    className: "",
    model: {
      adConnection: "Ja",
      adUsername: "",
      adPassword: "",
      adCellNumber: "",
      // vf_email: "",
    },
  };
  constructor(props) {
    super(props);
    this.state = this.generateState(props);
  }
  componentWillReceiveProps(props) {
    this.setState(this.generateState(props));
  }
  generateState(props) {
    const model = props.model || {};
    const adStatus_id =
      model.adStatus_id && model.adStatus_id !== ""
        ? model.adStatus_id
        : STATUS_IDS.NEW;
    return {
      adConnection: model.adConnection || "Ja",
      // vf_email: model.vf_email || "",
      adCellNumber: model.adCellNumber || "",
      adStatus_id,
      error: {
        // vf_email: null,
      },
    };
  }
  getEmptyModel = () => {
    return {
      adConnection: "Ja",
      // vf_email: "",
      adCellNumber: "",
    };
  };
  validate = (initialize) => {
    const { error, ...model } = this.state;
    // if (this.props.vfEmailEditable && model.vf_email === "") {
    //   this.setState({ error: { vf_email: true } });
    //   return null;
    // }
    if (initialize) {
      const state = this.getEmptyModel();
      this.setState(state);
    }
    return {
      ...this.props.model,
      ...model,
    };
  };
  getModel = (initialize = false) => {
    const model = this.validate(initialize);
    return model;
  };
  render() {
    const { className, model/* , readOnly, vfEmailEditable */ } = this.props;
    const { /* vf_email,  */adCellNumber, adStatus_id/* , error */ } = this.state;

    return (
      <div className={className}>
        {/* <div
          className={`form-group-item ${error.vf_email ? "error-group" : ""}`}
        >
          <Label htmlFor="vfEmail">
            {`Vodafone E-Mail`}
            {vfEmailEditable && "*"}
          </Label>
          <InputText
            id="vfEmail"
            className="w3-block w3-border w3-round pad"
            value={vf_email}
            disabled={!vfEmailEditable}
            onChange={(event) =>
              this.setState({
                vf_email: event.target.value,
                error: { vf_email: null },
              })
            }
            readonly={readOnly || !vfEmailEditable}
          />
        </div> */}
        {(!model ||
          (model.adConnection === "" && adStatus_id === STATUS_IDS.NEW)) && (
          <div className="form-group-item pad-top-">
            <Label htmlFor="adCellNumber">{`optional: Handynummer zur Vorregistrierung des Authenticators`}</Label>
            <InputText
              id="adCellNumber"
              className="w3-block w3-border"
              value={adCellNumber}
              placeholder="+49XXX"
              onChange={(event) =>
                this.setState({ adCellNumber: event.target.value })
              }
            />
          </div>
        )}
      </div>
    );
  }
}
